import React from 'react';
import './App.css';
import Header from './components/Header/Header.jsx';
import Flashcard from './components/Flashcard/Flashcard.jsx';

function App() {
  return (
    <div className="App">
      <Header />
      <Flashcard japanese="こんにちは" english="Hello" />
      {/* Other components will go here in the future */}
    </div>
  );
}

export default App;
