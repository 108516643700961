// firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCozDER-x2UxcjJkKtA06T0-7AarTsRMCQ",
  authDomain: "nomikaiwa-5a18a.firebaseapp.com",
  projectId: "nomikaiwa-5a18a",
  storageBucket: "nomikaiwa-5a18a.appspot.com",
  messagingSenderId: "874231833394",
  appId: "1:874231833394:web:e435541ed4e27f4c11ad2b",
  measurementId: "G-7707ZPJ85S"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, firestore, storage };