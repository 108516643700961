import React, { useState, useEffect } from 'react';
import './Flashcard.css';
import '../Header/Header.css'; // Import Header.css
import '../Footer/Footer.css'; // Import Footer.css
import { firestore } from '../../firebase'; // Import the 'firestore' instance
import { collection, getDocs } from 'firebase/firestore'; // Import required Firestore functions

function Flashcard() {
  const [flashcard, setFlashcard] = useState({ japanese: '', english: '' });

  const getRandomFlashcard = async () => {
    // Reference the 'flashcards' collection in Firestore
    const flashcardsRef = collection(firestore, 'flashcards');

    try {
      const querySnapshot = await getDocs(flashcardsRef); // Use getDocs() for SDK v9
      const flashcards = querySnapshot.docs.map((doc) => doc.data());
      const randomIndex = Math.floor(Math.random() * flashcards.length);
      const randomFlashcard = flashcards[randomIndex];

      //console.log('Fetched flashcards:', flashcards);
      //console.log('Random flashcard:', randomFlashcard);
      
      setFlashcard(randomFlashcard);
    } catch (error) {
      console.error('Error fetching flashcard:', error);
    }
  };

  useEffect(() => {
    getRandomFlashcard();
  }, []);

  const [activeLanguage, setActiveLanguage] = useState('japanese'); // Default to 'japanese'

  const switchLanguage = (language) => {
    setActiveLanguage(language);
  };

  const handleNextClick = () => {
    getRandomFlashcard();
  };

  return (
    <>
      <main>
        <div className="flashcard">
          <div className={`flashcard-japanese ${activeLanguage === 'japanese' ? 'active' : ''}`}>
            <p>{flashcard.japanese}</p>
          </div>
          <div className={`flashcard-english ${activeLanguage === 'english' ? 'active' : ''}`}>
            <p>{flashcard.english}</p>
          </div>
          <div className="language-toggle">  {/* Change to className="language-toggle" */}
            <button className={`language-toggle ${activeLanguage === 'japanese' ? 'active' : ''}`}
                    onClick={() => switchLanguage('japanese')}>
              日本語
            </button>
            <button className={`language-toggle ${activeLanguage === 'english' ? 'active' : ''}`}
                    onClick={() => switchLanguage('english')}>
              ENGLISH
            </button>
          </div>
          <button className="next-button" onClick={handleNextClick}>
            次へ
          </button>
        </div>
      </main>

      <footer className="footer">  {/* Change to className="footer" */}
        <p>ライアンがたっぷり❤️で作りました</p>
      </footer>
    </>
  );
}

export default Flashcard;
